<script lang="ts">
    import { base } from '$app/paths';
    import { toastDanger } from '../../basic/utils';
    import * as pdfjs from 'pdfjs-dist';
    import { onMount, onDestroy } from 'svelte';
    import { twMerge } from 'tailwind-merge';

    pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.js', import.meta.url);

    export let contract: Blob | string;
    export let loading = true;

    let pdfRef: HTMLDivElement;
    let pdfDoc;

    const loadPdf = async (url: string, cMapUrl: string) => {
        return await pdfjs.getDocument({
            url: url,
            cMapUrl: cMapUrl,
            cMapPacked: true, // 압축된 CMap 사용
        }).promise;
    };

    onMount(async () => {
        const isBlob = contract instanceof Blob;
        const url = isBlob ? URL.createObjectURL(contract) : (contract as string);

        pdfDoc = await loadPdf(url, `${base}/pdfjs/cmaps/`);

        if (pdfDoc) {
            await renderAllPages(pdfDoc);
            loading = false;
        } else {
            toastDanger('PDF 문서를 불러오는 중 오류가 발생했습니다.');
        }
    });

    onDestroy(() => {
        pdfDoc = pdfDoc?.destroy();
    });

    const renderAllPages = async pdfDoc => {
        for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
            await renderPage(pdfDoc, pageNum);
        }
    };

    const renderPage = async (pdfDoc, pageNum) => {
        const page = await pdfDoc.getPage(pageNum);

        // 부모 요소의 너비를 가져옵니다.
        const parentWidth = pdfRef?.parentElement?.offsetWidth || 0;

        // 페이지의 기본 viewport를 가져온 후, 부모 요소의 너비에 맞춰 스케일을 조정합니다.
        const viewport = page.getViewport({ scale: 1 });
        const scale = parentWidth / viewport.width; // 스케일 계산
        const devicePixelRatio = window.devicePixelRatio || 1; // 디바이스 픽셀 비율
        const scaledViewport = page.getViewport({ scale: scale * devicePixelRatio });

        const newCanvas = document.createElement('canvas');
        const canvasContext = newCanvas.getContext('2d');

        // 캔버스의 물리적 크기를 조정
        newCanvas.height = scaledViewport.height;
        newCanvas.width = scaledViewport.width;

        // CSS를 통해 캔버스의 표시 크기를 조정
        newCanvas.style.height = `${scaledViewport.height / devicePixelRatio}px`;
        newCanvas.style.width = `${scaledViewport.width / devicePixelRatio}px`;
        newCanvas.style.marginBottom = '1rem';
        newCanvas.style.backgroundColor = 'transparent';

        pdfRef?.appendChild(newCanvas);

        const renderContext = {
            canvasContext,
            viewport: scaledViewport,
            background: 'transparent',
        };

        await page.render(renderContext).promise;
    };
</script>

<div bind:this={pdfRef} class={twMerge('z-10 w-full bg-white', $$props.class)}>
    <!-- PDF 페이지들이 여기에 렌더링됩니다 -->
</div>
